import {
    Button,
    Chip,
    Paper,
    Typography
} from '@mui/material';

import { Box } from '../../components/box'

import { getTech } from './tech';

export interface PortfolioEntry {
    name: string
    category: string
    client?: string
    company?: string
    links: WebLink[]
    description: string,
    tech: string[],
    images: IImages[],
    timestamp: Date
}

export interface WebLink {
    name: string
    url: string
}

export interface IImages {
    url: string
}

function Portfolio() {

    let portfolioEntries: PortfolioEntry[] = [{
        name: 'Scratch Fix Pro',
        category: 'Online shop',
        client: 'ScratchFixPro',
        links: [{
            name: 'Website',
            url: 'https://scratchfixpro.co.za/'
        }],
        description: 'Online shop where customers can order paint for their specific vehicle by using the paintcode. Fully custom built because of the unique method of ordering products. Custom checkout using payfast.',
        tech: ["TypeScript", "React", "Node.JS", "MongoDB"],
        images: [{ url: '/portfolio/portfolio_scratchfixpro.jpg' }],
        timestamp: new Date('2021-10-01')
    },
    {
        name: 'IoT Asset Tracking Mobile App',
        category: 'Mobile App',
        client: 'ConocoPhillips',
        company: 'IoT.nxt',
        links: [
            {
                name: 'Apple App Store',
                url: 'https://apps.apple.com/za/app/conocophillips-asset-tracking/id1581072282'
            },
            {
                name: 'Google Play Store',
                url: 'https://play.google.com/store/apps/details?id=com.conocophillips&hl=en&gl=US'
            }
        ],
        description: 'The app enables technicians to manage assets by tagging them with NFC tags. This enables the business to more efficiently track assets in their operations by knowning when, where and by whom was an asset deployed.',
        tech: ["TypeScript", "ReactNative"],
        images: [{ url: '/portfolio/portfolio_conocoPhillipsApp.jpg' }],
        timestamp: new Date('2020-08-01')
    },
    ]

    return (<Box component="div" sx={{ mt: 6 }}>
        <Typography variant='h4' id="portfolio">Portfolio</Typography>
        <Typography sx={{ opacity: 0.7 }} paragraph>
            A sample of work I've completed. Let me know if you'd like me to build something for you too!
        </Typography>

        {portfolioEntries.map(e => <PortfolioEntryDisplay key={e.name} data={e} />)}
    </Box>)

}

function PortfolioEntryDisplay(props: { data: PortfolioEntry }) {
    return <Paper sx={{
        m: 0.5,
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row',
        },
        p: 0,
        mb: 4,
        overflow: 'hidden'
    }} elevation={15}>

        <Paper sx={{
            flex: 1,
            overflow: 'hidden',
            background: 'black',
            borderRadius: 0,
            position: 'relative',
            minHeight: 300
        }} elevation={1}>
            <Box component="img"
                src={props.data.images[0].url}
                sx={{
                    position: 'absolute',
                    aspectRatio: 1,
                    width: { xs: '100%', sm: 'auto' },
                    height: { xs: 'auto', sm: '100%' },
                    left: { xs: 0, sm: '-25%' },
                    flex: 1,
                    m: 0,
                    p: 0
                }} />
        </Paper>

        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: 2 }}>
            <Typography sx={{ opacity: 0.5 }} variant="caption">Name</Typography>
            <Typography sx={{ fontWeight: 600 }}>{props.data.name}</Typography>

            <Typography sx={{ opacity: 0.5 }} variant="caption">Client</Typography>
            <Typography>{props.data.client}</Typography>

            {props.data.company && <>
                <Typography sx={{ opacity: 0.5 }} variant="caption">Company</Typography>
                <Typography>{props.data.company}</Typography>
            </>}


            <Typography sx={{ opacity: 0.5 }} variant="caption">Description</Typography>
            <Typography paragraph>{props.data.description}</Typography>

            <Typography sx={{ opacity: 0.5 }} variant="caption">Links</Typography>
            <Box>
                {props.data.links.map(l => <Button
                    key={l.name}
                    component="a" target="_blank" sx={{
                        textDecoration: 'none',
                        cursor: 'pointer !important'
                    }} href={l.url}>{l.name}</Button>)}
            </Box>

            <Box>
                {props.data.tech.map(t => <Chip
                    key={t}
                    component="a"
                    onClick={() => { }}
                    size="small"
                    href={getTech(t).link}
                    target="_blank"
                    label={t}
                    sx={{ m: 0.5 }}
                />)}
            </Box>
        </Box>

    </Paper>
}

export default Portfolio;