

import {
    Button,
    CircularProgress,
    Typography
} from '@mui/material';
import * as React from 'react';
import {
    Suspense
} from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';
import { Box } from 'src/components/box'

import {
    Link as RouterLink,
    Outlet
} from 'react-router-dom';

const Racer = React.lazy(() => import('./webglracer/webglracer'));

interface ProjectsProps {

}

interface ProjectsState {

}

export class Projects extends React.Component<ProjectsProps, ProjectsState> {
    state = {}

    index = () => {
        return <Box>
            <Typography variant='h4'>Projects</Typography>
            <Typography sx={{ opacity: 0.7 }} paragraph>
                WARNING STILL ADDING THINGS HERE
            </Typography>

            <Button component={RouterLink} to="test1">Test1</Button>
            <Button component={RouterLink} to="racer">Racer</Button>
        </Box>
    }

    renderTest = () => {
        return <Typography>Test</Typography>
    }

    render() {
        return (<Routes>
            <Route element={<Outlet />}>
                <Route index element={<>{this.index()}</>} />
                <Route path="test1" element={<>{this.renderTest()}</>} />
                <Route path="racer" element={<Suspense fallback={<CircularProgress />}><Racer /></Suspense>} />
            </Route>
        </Routes>);
    }
}
