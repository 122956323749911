export interface ITechnology {
    name: string
    link: string
}

export const listOfTechnology: ITechnology[] = [
    {
        name: 'TypeScript',
        link: "https://www.typescriptlang.org/"
    },
    {
        name: 'Node.JS',
        link: "https://nodejs.org/"
    },
    {
        name: 'MongoDB',
        link: "https://www.mongodb.com/"
    },
    {
        name: 'MaterialUI',
        link: "https://mui.com/"
    },
    {
        name: 'WebGL',
        link: "https://threejs.org/"
    },
    {
        name: 'React',
        link: "https://reactjs.org/"
    },
    {
        name: 'ReactNative',
        link: "https://reactnative.dev/"
    }
]

export const getTech = (techname:string) => {
    return listOfTechnology.filter( t => t.name === techname)[0]
}