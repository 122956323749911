import { CloseTwoTone, MenuTwoTone } from '@mui/icons-material';
import {
    Toolbar,
    IconButton,
    CssBaseline,
    createTheme,
    ThemeProvider,
    Drawer,
    Typography,
    Button
} from '@mui/material';

import React from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { checkSystemDarkMode, DarkModeToggle, TDisplayMode } from './darkModeToggle';
import { Box } from 'src/components/box'

interface IRouterWrapperState {
    menuOpen: boolean
    displayMode: TDisplayMode
    darkMode?: boolean
}

export class RouterWrapper extends React.Component {
    state: IRouterWrapperState = {
        menuOpen: false,
        displayMode: 'system',
        darkMode: checkSystemDarkMode()
    }

    componentDidMount = () => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            console.log(e.matches);
            // const newColorScheme = e.matches ? "dark" : "light";
            if (this.state.displayMode === 'system') this.setState({ darkMode: e.matches });
        });
    }

    render() {


        const lightTheme = createTheme({
            palette: {},
        });

        const darkTheme = createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: "#008dcb",
                },
                secondary: {
                    main: "#f78419",
                    contrastText: "rgba(255,255,255,0.87)",
                },
                background: {
                    default: "#272b34",
                    paper: "#272b34",
                },
                text: {
                    primary: "#eeeeee",
                },
            },
        });

        return (<ThemeProvider theme={this.state.darkMode ? darkTheme : lightTheme}>
            <CssBaseline />


            <Toolbar variant="dense">
                {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuTwoTone />
                </IconButton> */}
                {/* <Typography variant="h6" color="inherit" component="div">
                    Photos
                </Typography> */}

                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={e => { this.setState({ menuOpen: true }) }}
                    aria-label="menu"
                    sx={{ ml: 2 }}>
                    <MenuTwoTone />
                </IconButton>
            </Toolbar>

            <Drawer
                anchor="right"
                open={this.state.menuOpen}
                onClose={() => {
                    this.setState({ menuOpen: false })
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mr: 3 }}>
                    <Box sx={{ flex: 1 }} />
                    <IconButton sx={{ position: 'relative', right: 0 }}
                        onClick={e => { this.setState({ menuOpen: false }) }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>

                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption">DARK MODE</Typography>
                    <DarkModeToggle onChange={(displayMode, darkMode) => { this.setState({ displayMode, darkMode }); }} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button component={RouterLink} to="/" onClick={() => { this.setState({ menuOpen: false }) }}>Home</Button>
                    <Button component={RouterLink} sx={{ opacity: 0.1 }} to="/projects"
                        onClick={() => { this.setState({ menuOpen: false }) }}>Projects</Button>
                </Box>
            </Drawer>

            
            <Outlet />
            
        </ThemeProvider>);
    }
}

export const PageView = (props: any ) => {
    return <Box sx={{ maxWidth: 750, margin: "0 auto", mt: 0, pl: 2, pr: 2 }} {...props} />
}