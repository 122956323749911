import {
    GitHub,
    LinkedIn,
    MailTwoTone,
    PictureAsPdf,
    YouTube
} from '@mui/icons-material';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import { Box } from 'src/components/box'
import * as React from 'react';
import Portfolio from '../portfolio/portfolio';
import { listOfTechnology } from '../portfolio/tech';
import { PageView } from '../wrapper/wrapper';


interface RouteHomeProps {

}

interface RouteHomeState {

}

class RouteHome extends React.Component<RouteHomeProps, RouteHomeState> {
    state = {}

    render() {
        return (<PageView>

            <Box>
                <Typography variant="caption">Hi, my name is</Typography>
                <Typography variant="h4" sx={{ fontWeight: '600' }} >Rouan van der Ende.</Typography>
                <Typography variant="h5" color="primary" >I build things for the web.</Typography>
                <Typography sx={{ opacity: 0.7 }} paragraph>
                    I'm a software engineer specializing in building and designing
                    next generation digital experiences. I'm currently looking for new
                    projects, so contact me!
                </Typography>


                <Button
                    component="a"
                    href="/resume_rouan_2022.pdf"
                    target="_blank"
                    variant="outlined"
                    sx={{ m: 0.5 }}
                    startIcon={<PictureAsPdf />}>
                    Download my resume</Button>

                <Button
                    component="a"
                    variant="contained"
                    sx={{ m: 0.5 }}
                    href="mailto:rouan@8bo.org"
                    startIcon={<MailTwoTone />}>email: rouan@8bo.org</Button>
            </Box>



            <Box sx={{ mt: 4 }}>
                <IconButton
                    color="primary"
                    component="a"
                    href="https://github.com/rvdende">
                    <GitHub />
                </IconButton>

                <IconButton
                    color="primary"
                    component="a"
                    href="https://www.linkedin.com/in/rouan/">
                    <LinkedIn />
                </IconButton>

                <IconButton
                    color="primary"
                    component="a"
                    href="https://www.youtube.com/c/RouanvanderEnde">
                    <YouTube />
                </IconButton>
            </Box>

            <Box sx={{ mt: 6 }}>
                <Typography variant='h4'>About Me</Typography>
                <Typography sx={{ opacity: 0.7 }} paragraph>
                    Hello! My name is Rouan and I enjoy creating what I consider to be
                    digital magic: From websites, apps to electronics. I first programmed during primary school
                    and never stopped ever since.
                </Typography>

                <Typography sx={{ opacity: 0.7 }} paragraph>
                    Fast-forward to today, and I've had the privilege of working at two advertising agencies, a startup
                    and a corporation. I've done design, animation, film, electronics, machining and fabrication. I've built products,
                    services and mobile apps. I've done backend servers and databases, frontends. Working in teams and worked solo.
                </Typography>
            </Box>

            <Box sx={{ mt: 4 }}>
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 500 }}
                    variant="caption">
                    I specialize in these technologies:
                </Typography>

                <Box sx={{ mt: 2 }}>
                    {listOfTechnology.map(t => <Chip
                        key={t.name}
                        component="a"
                        onClick={() => { }}
                        href={t.link}
                        target="_blank"
                        label={t.name}
                        sx={{ m: 0.5 }}
                    />)}
                </Box>

                <Portfolio />

            </Box>


        </PageView>);
    }
}

export default RouteHome;