import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import RouteHome from './home/home';
import { Projects } from "./projects/projectsRoute";
import { RouterWrapper } from './wrapper/wrapper';

function SiteRouter() {
    return (<BrowserRouter>
        <Routes>
            <Route path="/" element={<RouterWrapper />}>
                <Route index element={<RouteHome />} />
                <Route path="projects/*" element={<Projects />} />
                {/* <Route path="teams" element={<Teams />}>
                    <Route path=":teamId" element={<Team />} />
                    <Route path="new" element={<NewTeamForm />} />
                    <Route index element={<LeagueStandings />} />
                </Route> */}
            </Route>
        </Routes>
    </BrowserRouter>);
}

export default SiteRouter;